<template>
  <div class="pointer d-flex justify-content-center flex-wrap">
    <div :style="positionKey === 0 ? 'outline: medium solid #ef4927' : ''" @click="setAdPosition(-1)"
          class="box rounded mr-3 text-center d-flex justify-content-center align-items-center">ANY AVAILABLE
      POSITION
    </div>
    <!-- 1. done -->
    <div :style="positionKey === 4 ? 'outline: medium solid #ef4927' : ''" @click="setAdPosition(4)"
          class="pointer rounded box mr-3 above">
      <div class="header-split header-top text-light d-flex justify-content-center align-items-center">
        HEADER
      </div>
      <div class="header-split header-bottom"></div>
    </div>
    <!-- 2. done -->
    <div :style="positionKey === 5 ? 'outline: medium solid #ef4927' : ''" @click="setAdPosition(5)"
          class="pointer rounded box position-relative mr-3 below">
      <div class="footer-split footer-top text-light d-flex justify-content-center align-items-center">
        FOOTER
      </div>
      <div class="footer-split footer-bottom"></div>
    </div>
    <!-- 3. wip -->
    <div :style="positionKey === 6 ? 'outline: medium solid #ef4927' : ''" @click="setAdPosition(6)"
          class="pointer rounded box mr-3 header">
      <div class="sidebar-split sidebar-left text-light d-flex justify-content-center align-items-center">
        SIDEBAR
      </div>
      <div class="sidebar-split sidebar-right"></div>
      <!-- 4. done -->
    </div>
    <!-- 5. done -->
    <div :style="positionKey === 1 ? 'outline: medium solid #ef4927' : ''" @click="setAdPosition(1)"
          class="pointer rounded box mr-3 footer">
      <div class="above-split above-top text-light d-flex justify-content-center align-items-center">ABOVE
        THE FOLD
      </div>
      <div class="above-split above-bottom"></div>
    </div>
    <!-- 6. done -->
    <div :style="positionKey === 3 ? 'outline: medium solid #ef4927' : ''" @click="setAdPosition(3)"
          class="pointer rounded box mr-3 footer">
      <div class="below-split below-top text-light d-flex justify-content-center align-items-center">BELOW
        THE FOLD
      </div>
      <div class="below-split below-bottom"></div>
    </div>
    <div :style="positionKey === 7 ? 'outline: medium solid #ef4927' : ''" @click="setAdPosition(7)"
          class="pointer rounded box mr-3 d-flex justify-content-center align-items-center">FULL SCREEN
    </div>
  </div>
</template>

<script>
export default {
  props: ['positionKey'],
  data() {
    return {
      // position: this.positionKey,
    }
  },
  methods: {
    setAdPosition(key) {
      this.$emit('setPosition', key)
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/components/custom/banner-position/index.scss';
</style>
